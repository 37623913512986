import { NotificationModule } from "../notification/notificationModule";
import ConfirmationModalModule from "../confirmation/confirmationModalModule";


export class AsyncErrorModule {
    private body: any;
    private statusCode: number;

    private constructor() {
    }

    public static CreateFromJQueryXHR(xhr: JQuery.jqXHR): Promise<AsyncErrorModule> {
        const promise = new Promise<AsyncErrorModule>((resolve, reject) => {

            const asyncErrorModule = new AsyncErrorModule();
            if (xhr.responseJSON !== undefined) {
                asyncErrorModule.body = xhr.responseJSON;
                asyncErrorModule.statusCode = xhr.status;
            } else {
                asyncErrorModule.body = xhr.responseText;
                asyncErrorModule.statusCode = xhr.status;
            }

            resolve(asyncErrorModule);
        });

        return promise;
    }

    public static CreateFromResponse(response: Response): Promise<AsyncErrorModule> {
        const promise = new Promise<AsyncErrorModule>((resolve, reject) => {
            response.json().then(() => {
                let asyncErrorModule = new AsyncErrorModule();
                asyncErrorModule.body = response.body;
                asyncErrorModule.statusCode = response.status
                resolve(asyncErrorModule);
            });
        });

        return promise;
    }

    private getResponseBody() {
        return this.body;
    }

    private getStatusCode() {
        return this.statusCode;
    }

    private getIsValidationError() {
        const asyncErrorMessage = this.getResponseBody() as AsyncErrorMessage;

        if (asyncErrorMessage === undefined || asyncErrorMessage.ErrorMessage !== "ValidationError")
            return false;

        return true;
    }

    private getIsConcurrencyError() {

        const statusCode = this.getStatusCode();

        if (statusCode === 409)
            return true;

        return false;
    }

    private clearAllErrors() {
        $(".input-validation-error").removeClass("input-validation-error");
        $(".field-validation-error").removeClass("field-validation-error");
        $("span[data-valmsg-for]").text("");

        $(".modal-message").hide();
    }

    public handleError() {

        const self = this;

        if (this.getIsValidationError() === true) {

            this.clearAllErrors();

            this.showValidationErrorNotification();
            this.showValidationErrorOnInputs();
            this.showValidationErrorMessages();
            this.showValidationErrorOnSelect2();
            this.showValidationErrorOnFileUpload();

            return;
        }

        if (this.getIsConcurrencyError() === true) {

            const cofirmation = new ConfirmationModalModule(
                {
                    actionIsRequired: true,
                    okButtonText: "Overskriv ændringer",
                    deleteOnHide: true,
                    okCallback: function () {
                        const form = $("#PageIdentifier").closest("form");
                        form.data("overwrite", true);
                        form.trigger("submit");
                        cofirmation.hide();
                    },
                    cancelCallback: function () {
                        window.location.reload()
                    },
                    title: "Samtidighedsfejl",
                    body: self.body
                })

            cofirmation.show();

            return;
        }

        //Default handler
        NotificationModule.showErrorSmall("Der skete desværre en fejl under kommunikationen med webserveren. Hvis dette problem fortsætter så kontakt venligst en administrator for systemet.")
        console.log("Default error handler", this.body);
    }

    private getResponseAsValidationErrorList() {

        const validationErrorList: ValidationError[] = [];
        const asyncErrorMessage = this.getResponseBody() as AsyncErrorMessage;

        for (var key in asyncErrorMessage.ErrorData) {

            const htmlDecodedKey = $('<textarea />').html(key).text();
            const htmlDecodedValue = $('<textarea />').html(asyncErrorMessage.ErrorData[key]).text();

            validationErrorList.push({
                key: htmlDecodedKey,
                errorMessage: htmlDecodedValue
            });
        }

        return validationErrorList;
    }

    private showValidationErrorNotification() {

        const validationErrorList = this.getResponseAsValidationErrorList();

        let listHtml = "<ul>";

        validationErrorList.forEach((validationError) => {
            listHtml += "<li>" + validationError.errorMessage + "</li>";
        })

        listHtml += "</ul>";

        NotificationModule.showErrorSmall("Der skete følgende valideringsfejl:<br/>" + listHtml);
    }

    private showValidationErrorOnInputs() {

        const validationErrorList = this.getResponseAsValidationErrorList();

        validationErrorList.forEach((validationError) => {
            const input = $("input[name='" + validationError.key + "']");
            input.addClass("input-validation-error")
        });
    }

    private showValidationErrorMessages() {

        const validationErrorList = this.getResponseAsValidationErrorList();

        validationErrorList.forEach((validationError) => {

            const input = $("input[name='" + validationError.key + "'], select[name='" + validationError.key + "']");

            const errorText = validationError.errorMessage ? validationError.errorMessage : input.data("val-required");
            const fieldValidationSpan = $("span[data-valmsg-for='" + validationError.key + "']").text(errorText);

            fieldValidationSpan.addClass("field-validation-error")
        });
    }

    private showValidationErrorOnSelect2() {
        const validationErrorList = this.getResponseAsValidationErrorList();

        validationErrorList.forEach((validationError) => {

            const select = $("select[name='" + validationError.key + "']");

            const select2 = select.next(".select2").find(".select2-selection");

            select2.addClass("input-validation-error");
        });
    }

    private showValidationErrorOnFileUpload() {
        const validationErrorList = this.getResponseAsValidationErrorList();

        validationErrorList.forEach((validationError) => {

            const fileUpload = $("input[type=file][name='" + validationError.key + "']");

            const label = fileUpload.next("label");

            label.addClass("input-validation-error");
        });
    }
}

type errorType = "ValidationError" | "";

export interface AsyncErrorMessage {
    ErrorMessage: errorType;
    ErrorData: any;
}

export interface ValidationError {
    key: string,
    errorMessage: string
}
