import "select2";
import "select2/dist/js/i18n/da";

// Default select2 language to danish
($.fn.select2 as any).defaults.set("language", "da");


export interface IDropdownModuleOptions {
    language?: string;
    width?: string;
    searchable?: boolean;
    allowClear?: boolean;
    placeholder?: string;

    /**
     * Optional: Override additional select2 options. Experimental!! Be careful, this could also overwrite some of the stuff that DropdownModule does to modify select2.
     */
    additionalSelect2Options?: Select2.Options;
}

export default class DropdownModule {

  public init(selector: string | HTMLElement, options?: IDropdownModuleOptions) {
    this.initDropdownModule(selector, options);
  }

  public destroy(selector: string | HTMLElement) {
    $(selector as any).select2("destroy");
  }

  public open(selector: string | HTMLElement) {
    $(selector as any).select2("open");
  }

  private initDropdownModule(selector: string | HTMLElement, options?: IDropdownModuleOptions) {

    $(selector as any).each(function(index, element) {

      const $element = $(element);

      if ($element.data("dropdown-module-initialized") === true) {
        return;
      }

      const select2Options: any = {
        language: "da",
        width: "100%",
      };

      if (options !== undefined && options.searchable === false) {
        select2Options.minimumResultsForSearch = Infinity;
      }

      const placeholderAttribute = $element.attr("placeholder");
      if (placeholderAttribute !== undefined && placeholderAttribute.length > 0) {
        select2Options.allowClear = true;
        select2Options.placeholder = placeholderAttribute;
      }

      let combinedOptions = $.extend(true, {}, select2Options, options);

      if(options !== undefined && options !== null && options.additionalSelect2Options !== undefined && options.additionalSelect2Options !== null) {
        combinedOptions = $.extend(true, {}, combinedOptions, options.additionalSelect2Options);
      }

      $element.select2(combinedOptions);

      //const $arrowContainer = $element.next(".select2.select2-container").find(".select2-selection__arrow");
      //$arrowContainer.html(
      //  "<svg class=\"embla-icon dropdown-arrow\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\">" +
      //    "<use xlink:href=\"/dist/icons/sprite.symbol.svg#arrow-down\"></use>" +
      //  "</svg>"
      //);
    });
  }
}
