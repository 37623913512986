enum ToggleState {
    ToggledIn = 1,
    ToggledOut = 2
}

export class Sidebar {
    private _toggleState: ToggleState;

    constructor() {
        this._toggleState = this.LoadToggleState();
    }

    public init() {
        $(".sidebar-toggle").on("click", (e) => {
            this.Toggle();
        });
    }

    private LoadToggleState() {
        const toggleState = localStorage.getItem("ToggleState");

        if (!toggleState) {
            return ToggleState.ToggledOut;
        }

        if (toggleState === ToggleState.ToggledIn.toString()) {
            this.ToggleIn();
            return ToggleState.ToggledIn;
        }

        return ToggleState.ToggledOut;
    }

    private SaveToggleState(toggleState: ToggleState) {
        localStorage.setItem("ToggleState", toggleState.toString());
    }

    private Toggle() {
        if (this._toggleState === ToggleState.ToggledOut) {
            this.ToggleIn();
        } else {
            this.ToggleOut();
        }

        this.SaveToggleState(this._toggleState);
    }

    private ToggleIn() {
        $("#sidebar").attr("data-toggle", "in");
        this._toggleState = ToggleState.ToggledIn;
    }

    private ToggleOut() {
        $("#sidebar").attr("data-toggle", "out");
        this._toggleState = ToggleState.ToggledOut;
    }
}

$(() => {
    new Sidebar().init();
});