/*
    Downloaded mobiscroll at https://download.mobiscroll.com/
    Choose Components:
        - Calendar (used in Embla for datetimepicker)

    Choose Themes:
        - Mobiscroll

    Choose Icon Set:
        - None selected
*/

import "../../libs/mobiscroll/js/mobiscroll.jquery.min";
import * as moment from "moment";
import { MbscCalendarOptions, Calendar as MbscCalendar } from "../../libs/mobiscroll/js/mobiscroll.jquery.min";

/**
 * Datepicker module options
 */
export interface ICalendarModuleOptions {
    /**
     * Date format to when a date is picked
     */
    dateFormat?: string;
    /**
     * Time format to when a date is picked
     */
    timeFormat?: string;
    /**
     * Localization "da" is default, use "en" for english version
     */
    lang?: string;
    /**
     * This event is triggered when a date is selected
     */
    onSetDate?: (event: any, inst: any) => void;
    /**
     * This event is triggered when a Set button is clicked
     */
    onSet?: (event: any, inst: any) => void;
    /**
     * This event is triggered when the datepicker is initialized
     */
    onInit?: (event: any, inst: any) => void;
    /**
     * Min date for datepicker
     */
    min?: moment.Moment;
    /**
     * Max date for datepicker
     */
    max?: moment.Moment;
    /**
     * Rendering mode, default value is "calender". Possible values: ['time', 'calendar'], ['date', 'calendar'] or [calendar]
     */
    calenderTypeOption?: DatetimePickerTypeEnum;

    /**
     * If true, the scroll wheels are circular.
     */
    circular?: boolean | boolean[];

    /**
     * If true clear button is visible
     */
    allowClear?: boolean;

    /**
     * Suppress the warning when the datetimepicker is initialized on multiple elements
     */
    suppressMultipleElementsWarning?: boolean;

    /**
     * Optional: Override additional mobiscroll settings. Does not override datepicker settings defined in ICalendarModuleOptions.
     */
    additionalMbscCalenderOptions?: MbscCalendarOptions;
}

interface IMobiscrollCalendarOptions extends MbscCalendarOptions {
    responsive?: IMobiscrollResponsiveOptions;
}

interface IMobiscrollResponsiveOptions {
    xsmall?: IMobiscrollResponsiveOption;
    small?: IMobiscrollResponsiveOption;
    medium?: IMobiscrollResponsiveOption;
    large?: IMobiscrollResponsiveOption;
    xlarge?: IMobiscrollResponsiveOption;
}

export enum DatetimePickerTypeEnum {
    Time = 1,
    Date = 2,
    DateAndTime = 3
}

interface IMobiscrollResponsiveOption {
    touchUi?: boolean;
    buttons?: string[];
}

export default class DatetimePickerModule {

    private $element: JQuery;

    private mobiscrollInstance: MbscCalendar;

    private readonly moduleInitKey = "calendarmoduleinitialized";

    /**
     * Init the datepicker
     * @param selector Selector or HTML element for the datepicker you want to initialize
     * @param options Options for the datepicker
     */
    constructor(selector: string | HTMLElement, options?: ICalendarModuleOptions) {
        this.initCalendarModule(selector, options);
    }

    /**
     * Get DOM elements, which the datetimepicker was initialized on
     */
    public getDomElements() {
        return this.$element;
    }

    /**
     * Get the underlying mobiscroll instance, which the datetimepicker uses
     */
    public getMobiscrollInstance(): MbscCalendar {
        return this.mobiscrollInstance;
    }

    /**
     * Set the value of the datetimepicker
     * @param value Value to set as a moment object
     * @param triggerChange If setValue should trigger a DOM change event on the datetimepicker input
     */
    public setValue(value: moment.Moment, triggerChange?: boolean) {

        if (triggerChange === undefined || triggerChange === null) {
            triggerChange = true;
        }

        this.mobiscrollInstance.setVal(value, true, triggerChange);
    }

    /**
     * Gets the value as a moment object
     */
    public getValue() {
        const value = this.mobiscrollInstance.getVal(false);
        const valueAsMoment = this.convertValueToMoment(value);

        return valueAsMoment;
    }

    /**
     * Gets the temporary value as a moment object. The temporary value is the value prior to clicking the Set button.
     */
    public getTempValue() {
        const value = this.mobiscrollInstance.getVal(true);
        const valueAsMoment = this.convertValueToMoment(value);

        return valueAsMoment;
    }

    private convertValueToMoment(value: any) {
        return moment(value);
    }

    private initCalendarModule(selector: string | HTMLElement, options: ICalendarModuleOptions): void {

        this.$element = $(selector as any);

        const defaultOptions: IMobiscrollCalendarOptions = {
            controls: ["calendar", "time"],
            lang: "da",
            dateFormat: "dd/mm/yy",
            setOnDayTap: false,
            timeFormat: "HH:ii",
            focusTrap: false,
            onSetDate: function(event: any, instance: any) {
                if (event.control === "calendar") {
                    instance.changeTab("time");
                }
            },
            responsive: {
                xsmall: { // xsmall = min-width: 0px
                    touchUi: true,
                    buttons: options.allowClear === true ? ["cancel", "clear", "set"] : ["cancel", "set"]
                },
                xlarge: { // xlarge = min-width: 1200px
                    touchUi: false,
                    buttons: options.allowClear === true ? ["clear"] : []
                }
            },
            circular: true,
            onShow: (event, instance) => {
                const $inputGroupParent = $(instance.element).parent(".input-group");
                $inputGroupParent.addClass("focus");
            }
        };

        let calendarOptions = defaultOptions;

        if (options !== undefined) {
            calendarOptions = $.extend(true, defaultOptions, options);

            if (options.calenderTypeOption !== undefined && options.calenderTypeOption !== null) {
                if (options.calenderTypeOption === DatetimePickerTypeEnum.Date) {
                    calendarOptions.controls = ["calendar", "date"];
                    calendarOptions.setOnDayTap = true;
                } else if (options.calenderTypeOption === DatetimePickerTypeEnum.DateAndTime) {
                    calendarOptions.controls = ["calendar", "time"];
                } else if (options.calenderTypeOption === DatetimePickerTypeEnum.Time) {
                    calendarOptions.controls = ["time"];
                }
            }
        }

        if (options.additionalMbscCalenderOptions !== undefined) {
            calendarOptions = $.extend(true, {}, calendarOptions, options.additionalMbscCalenderOptions);
        }

        if (this.$element.length > 1 && options.suppressMultipleElementsWarning !== true) {
            console.warn("Datetimepicker initialized on multiple elements, which will lead to the returned instance only affecting the first elements");
        }

        this.$element.each((index, element) => {
            const $element = $(element);

            $element.find("input").attr("autocomplete", "off");
            $element.attr("autocomplete", "off");
            $element.data(this.moduleInitKey, true);

            const $inputGroupParent = $element.parent(".input-group");
            if ($inputGroupParent !== undefined && $inputGroupParent.length > 0) {
                $inputGroupParent.find(".input-group-addon").on("click", (e) => {
                    if ($element.is(":disabled") !== true) {
                        $element.trigger("click");
                    }
                });
            }
        });

        this.$element.mobiscroll().calendar(calendarOptions);
        this.mobiscrollInstance = this.$element.mobiscroll("getInst");
    }
}
