////import * as moment from "moment";
//import "moment/locale/da";
import "./jQueryExtensions";
//import "./libs/jQueryEllipsis/jquery.ellipsis.min.js";
import { AsyncErrorModule } from "./Modules/asyncError";
import { NotificationModule } from "./Modules/notification";
import { RequiredFieldMarker } from "./Modules/requiredFieldmarker";
import ClickableRowsModule from "./Modules/clickableRows";
import { DatetimePickerModule, DatetimePickerTypeEnum } from "./Modules/datetimepicker";
//import "jquery.are-you-sure";

import "datatables.net"
import "datatables.net-bs4"
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";

import "jquery-validation"
import "jquery-validation-unobtrusive"
import DropdownModule from "./Modules/dropdown";

// // tslint:disable-next-line:no-var-requires
// require("jquery-validation");
// // tslint:disable-next-line:no-var-requires
// require("jquery-validation-unobtrusive");

declare global {
    interface Window {
        successMessage: string;
        infoMessage: string;
        errorMessage: string;
    }
}

// Disable ajax caching
$.ajaxSetup({ cache: false, traditional: true });

$(document).ajaxError((event, jqXHR, settings, thrownError) => {
    console.log("ajax error", "xhr", jqXHR, "thrownError", thrownError, "responseText", jqXHR.responseText);

    if (thrownError === "abort") { // Do nothing if error is an aborted ajax request
        return;
    }

    AsyncErrorModule.CreateFromJQueryXHR(jqXHR)
        .then((asyncErrorModule) => {
            asyncErrorModule.handleError();
        });
});

// Default select2 language to danish
// ($.fn as any).select2.defaults.set("language", "da");

(function () {

    $(document).ready(function () {

        //$("form:not([data-trackchanges=false])").areYouSure();

        new DatetimePickerModule(".datepicker", {
            calenderTypeOption: DatetimePickerTypeEnum.Date,
            dateFormat: "dd/mm/yy"
        });

        $("body").removeClass("preload"); // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/

        $.extend(true, $.fn.dataTable.defaults, {
            // "paging": false,
            // "ordering": false,
            // "info": false,
            // "searching": false,
            // "processing": true,
            // "serverSide": true,
            "language": {
                "sProcessing": "Henter...",
                "sLengthMenu": "Vis _MENU_ linjer",
                "sZeroRecords": "Ingen linjer matcher s&oslash;gningen",
                "sInfo": "Viser _START_ til _END_ af _TOTAL_ linjer",
                "sInfoEmpty": "Viser 0 til 0 af 0 linjer",
                "sInfoFiltered": "(filtreret fra _MAX_ linjer)",
                "sInfoPostFix": "",
                "sSearch": "S&oslash;g:",
                "sUrl": "",
                "oPaginate": {
                    "sFirst": "F&oslash;rste",
                    "sPrevious": "Forrige",
                    "sNext": "N&aelig;ste",
                    "sLast": "Sidste"
                }
            }
        });

        const dataTable = $("table").DataTable(
            {
                order: [1, 'desc'],
            });

        enableRowClick();

        const dropdownModule = new DropdownModule();
        dropdownModule.init("select:not(.no-select2)", {
            language: "da",
            width: "100%"
        });

        RequiredFieldMarker.markRequiredInputFields();

        // Notificationer beskeder
        if (window.successMessage !== undefined && window.successMessage.length > 0) {
            const successMessages = window.successMessage.split(";");
            for (const message of successMessages) {
                NotificationModule.showSuccessSmall(message);
            }
        }
        if (window.infoMessage !== undefined && window.infoMessage.length > 0) {
            const infoMessages = window.infoMessage.split(";");
            for (const message of infoMessages) {
                NotificationModule.showInfoSmall(message);
            }
        }

        if (window.errorMessage !== undefined && window.errorMessage.length > 0) {
            const errorMessages = window.errorMessage.split(";");
            for (const message of errorMessages) {
                NotificationModule.showErrorSmall(message);
            }
        }

        enableSelect2OpenOnFocus();
    });
})();

function enableSelect2OpenOnFocus() {

    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });

    // steal focus during close - only capture once and stop propogation to prevent focus loop
    $('select:not(.no-select2)').on('select2:closing', function (e) {
        $(e.target).data("select2").$selection.one('focus focusin', function (e) {
            e.stopPropagation();
        });
    });
}

function enableRowClick() {
    $('table tbody').on('click', 'tr', (event) => {
        let url = $(event.currentTarget).data("clickable-row-url");

        if (!url) return;

        if ((event.which && (event.which === 2 || event.which === 3)) || (event.button && event.button === 4) || event.ctrlKey) { // Middle button or control
            window.open(url);
        } else {
            window.location.href = url;
        }
    });
}

export const initDefaultTableRowClick = ($row: JQuery<HTMLElement>, url: string) => {
    $row.addClass("clickable-row");
    $row.on("click", "td", (event) => {
        const $target = $(event.target);
        if ($target.hasClass("expand-arrow") || ($target.closest(".expand-arrow").length > 0)
            || $target.hasClass("child") || ($target.closest(".child").length > 0)
            || $target.hasClass("exclude-click-event") || ($target.closest(".exclude-click-event").length > 0)
            || $target.hasClass("datatable-row-select-label") || ($target.closest(".datatable-row-select-label").length > 0)) {
            return;
        }

        if ((event.which && (event.which === 2 || event.which === 3)) || (event.button && event.button === 4) || event.ctrlKey) { // Middle button or control
            window.open(url);
        } else {
            window.location.href = url;
        }
    });
};