interface JQuery {
    serializeObject(): JQuery;
    areYouSure(): JQuery;
    autoHeight(): JQuery;
}

$.fn.extend({
    serializeObject: function () {
        var results = {},
            arr = this.serializeArray();
        for (var i = 0, len = arr.length; i < len; i++) {
            var obj = arr[i];
            //Check if results have a property with given name
            if (results.hasOwnProperty(obj.name)) {
                //Check if given object is an array
                if (!results[obj.name].push) {
                    results[obj.name] = [results[obj.name]];
                }
                results[obj.name].push(obj.value || '');
            } else {
                results[obj.name] = obj.value || '';
            }
        }
        return results;
    },
    autoHeight: function () {
        $(this).height('auto').height($(this)[0].scrollHeight);
        $(this).on("input", function () {
            $(this).height('auto').height($(this)[0].scrollHeight);
        });
        return this;
    }
});