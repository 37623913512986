class RequiredFieldMarker {


    public static markRequiredInputFields() {
        const inputs = $("input[data-val-required]:not(input[type=checkbox]), select[data-val-required], textarea[data-val-required]");
        const formGroups = inputs.closest(".form-group");
        formGroups.addClass("required");
    }
}

export { RequiredFieldMarker };
